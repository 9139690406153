<template>
  <div class="container">
    <div class="mx-auto loginContent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        > LOGIN
      </div>
      <div class="card text-center">
        <div class="card-body">
          <h2 class="text-primary">Login to Your Account</h2>
          <p>
            If you haven't created an account, please click here to
            <a
              class="text-primary"
              href="javascript:void(0);"
              @click="$router.push('/create-account').catch(() => {})"
              >create an account</a
            >.
          </p>
          <hr />
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.loginForm"
          />
          <ValidationObserver ref="loginForm">
            <form @submit.prevent="onSubmit">
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required"
                    name="Username"
                    id="loginUsername"
                    v-model="loginUsername"
                    placeholder="Enter your username"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required"
                    name="Password"
                    id="loginPassword"
                    v-model="loginPassword"
                    type="password"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <button type="submit" class="btn btn-primary">SUBMIT</button>
              <p class="mt-3">
                <span style="color: red">*</span> Indicates a required field
              </p>
              <p>
                <a
                  class="text-primary"
                  href="javascript:void(0);"
                  @click="$router.push('/forgot-login').catch(() => {})"
                  >Forgot your username or password?</a
                >
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "Login",
  title: "Login",
  data() {
    return {
      loginUsername: "",
      loginPassword: "",
      errors: []
    };
  },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert
  },
  methods: {
    onSubmit() {
      const loginUsername = this.loginUsername;
      const loginPassword = this.loginPassword;
      this.$refs.loginForm
        .validate()
        .then(async success => {
          if (!success) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.loginForm.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error["value"].length);
              this.errors = errors;
              this.$refs.loginForm.refs[errors[0]["key"]].$el.scrollIntoView({
                behavior: "smooth",
                block: "center"
              });
            }, 100);
          } else {
            this.$store.commit("auth/setLoading", true);
            this.errors = [];
            const errorMessage = await this.$store.dispatch("auth/login", {
              loginUsername,
              loginPassword,
              authSource: "local"
            });
            if (errorMessage) {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: errorMessage,
                layer: "public"
              });
            }
          }
        })
        .finally(() => {
          this.$store.commit("auth/setLoading", false);
        });
    }
  },
  created() {
    this.$store.dispatch("auth/clearTokens");
    this.$store.commit("auth/setLoading", false);
  }
};
</script>
